export default{
	methods: {
		openModal(id){
			let el = document.querySelector("#"+id);
			el.classList.add('show');
			el.style.display = "block";
			let back = document.querySelector(".backdrop__bar");
			back.classList.remove('d-none');
			let body = document.getElementsByTagName("body")[0];
			body.classList.add('modal-open');
		},
		modalIsOpen(id){
			let el = document.querySelector("#"+id);
			return el.classList.contains("show");
		},
		closeModal(id, closeBackdrop){
			let el = document.querySelector("#"+id);
			if(!el){
				return;
			}
			el.classList.remove('show');
			el.style.display = "none";

			if(typeof closeBackdrop == 'undefined'){
				let back = document.querySelector(".backdrop__bar");
				back.classList.add('d-none');	
			}			

			let body = document.getElementsByTagName("body")[0];
			body.classList.remove('modal-open');
		},
		getAllData(form){	     
	         // let myForm = document.getElementById(form); 
	         let formData = new FormData(form);
	         const data = {}; 
	         // need to convert it before using not with XMLHttpRequest
	         for (let [key, val] of formData.entries()) {
	               Object.assign(data, {[key]: val})
	         }
	         //console.log(data);
	         return data;
        },
	}
}