import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
  	payload: {},
    periods: {},
    books: {},    
    period_menu: {},
    loadingBook: true,    
    allMenusByUnit: [],
  },
  actions,
  getters,
  mutations,
};