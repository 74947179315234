import Book from '@/models/Book';

export default {
  async getBooks(context, params) {
    try {
      context.dispatch('App/loading', true, { root: true });
      context.commit('loadingBook', true);

      params.unit = context.rootGetters['User/unit'];
      const response = await Book.getBooks(params);

      context.commit('setBooks', response.data.data);
      context.dispatch('App/loading', false, { root: true });
      context.commit('loadingBook', false);

      return response.data.data;
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async clearPayload(context) {
    context.commit('clearPayload');
  },
  async setPayload(context, value) {
    context.commit('setPayload', value);
  },
  async setPeriodsModal(context, params) {
    try {
      context.dispatch('App/loading', true, { root: true });

      params.unit_id = context.rootGetters['User/unit'];

      await context.commit('setPayload', params);

      const response = await Book.getPeriods(params);

      context.dispatch('App/loading', false, { root: true });
      context.commit('setPeriodsModal', response.data.data);

      return response.data.data;
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async setPeriodMenu(context, params) {
    try {
      context.dispatch('App/loading', true, { root: true });

      await context.commit('setPayload', params);

      const response = await Book.getPeriodMenu(context.getters.payload);

      context.dispatch('App/loading', false, { root: true });
      context.commit('setPeriodMenu', response.data);

      return response.data;
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async saveBook(context, params) {
    try {
      context.dispatch('App/loading', true, { root: true });

      const payload = context.getters.payload;

      const response = await Book.saveBook(payload);

      const data = response.data.data;

      context.dispatch('App/loading', false, { root: true });
      context.commit('clearPayload');

      if (payload.menu_id) {
        if (data) {
          context.dispatch('App/setModalAlert', {
            closeModal: 'modal-schedule',
            type: 'book',
            title: 'Reserva concluída!',
            message: 'Dados da Reserva',
            response: data,
            open: true,
          }, { root: true });
        }
      } else {
        context.dispatch('App/setModalAlert', {
          closeModal: 'modal-schedule',
          type: 'book-cancel',
          title: 'Reserva cancelada!',
          message: 'Dados da Reserva',
          image: 'cancel.png',
          response: data,
          open: true,
        }, { root: true });
      }

      return data;
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async getMenu(context, params) {
    try {
      context.dispatch('App/loading', true, { root: true });
      await context.commit('setPayload', params);
      
      const response = await Book.getMenu(context.getters.payload);

      context.dispatch('App/loading', false, { root: true });

      if (response.data.reached_limit) {
        context.dispatch('App/setAlert', 'Este menu não está mais disponível', { root: true });
      }

      return response;
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async getAllMenusByUnit(context, params) {
    try {
      context.dispatch('App/loading', true, { root: true });

      const response = await Book.getAllMenusByUnit(params);

      context.commit('setAllMenusByUnit', response.data.menus);

      return response.data.menus;
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
};