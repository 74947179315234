import axios from 'axios';

export default {	
	saveOrder(params){
		return axios.post('/api/v1/restaurant/save-order', params);
	},
	getOrders(params){
		return axios.post('/api/v1/restaurant/get-orders', params);
	},
	getPeriods(params){
		params['is_order'] = true;
		return axios.post('/api/v1/restaurant/get-periods', params);
	},
	getPeriodMenu(params){
		return axios.post('/api/v1/restaurant/get-period-menu', params);
	},
	getOrderPeriod(params){
		return axios.post('/api/v1/restaurant/get-orders-period', params);
	},
	getLocalities(params){
		return axios.post('/api/v1/restaurant/get-localities', params);
	},
	deleteOrder(params){
		return axios.post('/api/v1/restaurant/delete-order', params);
	}
}