var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal modal-data-person fade in",attrs:{"tabindex":"-1","role":"dialog","id":"modal-change-password"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('a',{attrs:{"data-dismiss":"modal"},on:{"click":function($event){!_vm.hasToUpdatePassword()
              ? _vm.$root.closeModal('modal-change-password')
              : _vm.denyMessage()}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"15.153","height":"13.639","viewBox":"0 0 15.153 13.639"}},[_c('path',{attrs:{"d":"M8.27,107.648l-2.8-2.8h8.66a1.026,1.026,0,0,0,1.026-1.026v-1.026a1.026,1.026,0,0,0-1.026-1.026H5.467l2.8-2.8a1.026,1.026,0,0,0,0-1.451l-.726-.726a1.026,1.026,0,0,0-1.451,0L.3,102.579a1.026,1.026,0,0,0,0,1.451l5.794,5.794a1.026,1.026,0,0,0,1.451,0l.726-.726a1.026,1.026,0,0,0,0-1.451Z","transform":"translate(0.001 -96.485)","fill":"#005aa1"}})])]),(_vm.hasToUpdatePassword())?_c('h2',{staticClass:"modal-title"},[_vm._v(" Atualização da password ")]):_c('h2',{staticClass:"modal-title"},[_vm._v("Alterar Password")]),(_vm.hasToUpdatePassword())?_c('button',{staticClass:"close",attrs:{"data-dismiss":"modal","type":"button","aria-label":"Close"},on:{"click":function($event){_vm.hasToUpdatePassword()
              ? _vm.doLogout()
              : _vm.$root.closeModal('modal-change-password')}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])]):_vm._e()]),(_vm.hasToUpdatePassword())?_c('div',{staticClass:"text-center pb-5"},[_c('span',{staticClass:"text-danger"},[_vm._v("É necessário atualizar a sua password a cada 3 meses.")])]):_vm._e(),_c('div',{staticClass:"modal-body"},[_c('form',{ref:"formPassword",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('input',{attrs:{"type":"hidden","name":"modal_origin"},domProps:{"value":_vm.modalOrigin}}),_c('div',{staticClass:"modal-body-data-person"},[_c('div',{staticClass:"content-input"},[_c('label',[_vm._v(" Nova Password ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"type":"password","name":"password","value":"","placeholder":"","required":""},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),(_vm.validPassword == false)?_c('small',{staticClass:"form-text text-danger",attrs:{"id":"passwordHelp"}},[_vm._v("A password deve conter: "),_c('span',{class:_vm.has_minimum_lenth ? 'has_required' : ''},[_vm._v("Mínimo 8 caracteres")]),_vm._v(", "),_c('span',{class:_vm.has_uppercase ? 'has_required' : ''},[_vm._v("pelo menos uma maiúscula")]),_vm._v(", "),_c('span',{class:_vm.has_number ? 'has_required' : ''},[_vm._v("pelo menos 1 número")]),_vm._v(", e "),_c('span',{class:_vm.has_special ? 'has_required' : ''},[_vm._v("pelo menos 1 caracter especial")])]):_c('small',{staticClass:"form-text text-success"},[_vm._v(" Password válida! ")])]),_vm._m(0)]),_c('button',{staticClass:"modal-body-btn-record-changes",style:({
              backgroundColor: !_vm.validPassword ? '#ccc' : '',
              opacity: !_vm.validPassword ? 0.5 : 1,
              cursor: !_vm.validPassword ? 'not-allowed' : 'pointer',
            }),attrs:{"type":"submit","disabled":!_vm.validPassword}},[_vm._v(" Gravar Alterações ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-input"},[_c('label',[_vm._v(" Confirmação Password ")]),_c('input',{attrs:{"type":"password","name":"password_confirmation","value":"","placeholder":"","required":""}})])
}]

export { render, staticRenderFns }