<template>
  <div class="modal modal-schedule fade in show" tabindex="-1" role="dialog" id="modal-schedule-order">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <a @click="$root.closeModal('modal-schedule-order')" class="modal-header" data-dismiss="modal">
          <svg xmlns="http://www.w3.org/2000/svg" width="15.153" height="13.639" viewBox="0 0 15.153 13.639">
            <path
              d="M8.27,107.648l-2.8-2.8h8.66a1.026,1.026,0,0,0,1.026-1.026v-1.026a1.026,1.026,0,0,0-1.026-1.026H5.467l2.8-2.8a1.026,1.026,0,0,0,0-1.451l-.726-.726a1.026,1.026,0,0,0-1.451,0L.3,102.579a1.026,1.026,0,0,0,0,1.451l5.794,5.794a1.026,1.026,0,0,0,1.451,0l.726-.726a1.026,1.026,0,0,0,0-1.451Z"
              transform="translate(0.001 -96.485)" fill="#005aa1" />
          </svg>
          <h2 class="modal-title">Pedido</h2>
        </a>
        <div class="modal-body">
          <div class="modal-body-period">
            <div class="modal-body-period-header">
              <h2>Selecionar período</h2>
            </div>

            <div class="modal-body-period-element d-block">
              <div v-for="(period, periodIndex) in periods" :key="periodIndex" class="w-100"
                style="margin-left: -15px !important;">
                <div class="container-fluid d-flex align-items-center justify-content-left">
                  <div class="row">
                    <div class="col d-flex justify-content-center">
                      <a @click="loadHourDelivery(period)" :disabled="!period.has_click_active.status" :class="{
                        isDisabled: !period.has_click_active.status,
                        linkActive: period.id == payload.period_id,
                      }">
                        {{
                          period.name_order ? period.name_order : period.name
                        }}
                      </a>
                    </div>
                  </div>
                  <div class="row w-100">
                    <div class="col text-center">
                      <div class="alert alert-primary custom-heigth d-flex" role="alert">
                        <small>{{ period.has_click_active.message }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <div v-if="hour_deliveries && hour_deliveries.length > 0" class="modal-body-period">
            <div class="modal-body-period-header">
              <h2>Hora da entrega</h2>
            </div>
            <div class="modal-body-period-element">
              <div v-for="(period, periodIndex) in hour_deliveries" :key="periodIndex">
                <a @click="setHourDelivery($event, period)" :disabled="checkIfAllowHour(period)" :class="{
                  isDisabled: checkIfAllowHour(period),
                  linkActive: period.name == payload.hour_delivery,
                }">
                  {{ period.name }}
                </a>
              </div>
            </div>
          </div>
          <!--  -->
          <div v-if="Object.keys(period_menu).length > 0" class="modal-body-meal">
            <div class="modal-body-meal-header">
              <h2>Escolha a refeição</h2>
            </div>
            <div class="modal-body-meal-element">
              <template v-for="(periodMenu, periodMenuIndex) in period_menu.menus">
                <a @click="setProductSelect(periodMenu)" :key="periodMenuIndex" v-if="periodMenu.custom_code != 0"
                  :class="{
                    links: true,
                  }">
                  <div :class="{
                    'links-icon': true,
                    linkActive: periodMenu.id == payload.menu_product_id,
                  }">
                    <img class="img-fluid" :src="periodMenu.image_color" />
                  </div>
                  <p class="links-text">{{ periodMenu.name }}</p>
                </a>
              </template>
            </div>
          </div>
          <!--  -->
          <div v-if="niveis && niveis.length > 0" class="modal-body-options">
            <div class="modal-body-options-header">
              <h2>Opções do prato</h2>
            </div>
            <div class="modal-body-options-element">
              <div id="accordion">
                <template v-for="(nivel, nivelIndex) in niveis">
                  <div v-if="nivel.niveis.length > 0" :key="nivelIndex">
                    <div class="card">
                      <div class="card-header" :id="'nivel-card-' + nivelIndex">
                        <h5 class="mb-0">
                          <button class="btn btn-link" data-toggle="collapse"
                            :data-target="'#collapse-nivel-' + nivelIndex" aria-expanded="true"
                            :aria-controls="'collapse-nivel-' + nivelIndex">
                            {{ nivel.descricao }}
                            <img class="img-fluid" src="../assets/img/schedules/arrow-down.png" alt="arrow down"
                              title="arrow down" />
                          </button>
                        </h5>
                      </div>
                      <div :id="'collapse-nivel-' + nivelIndex" class="collapse show"
                        :aria-labelledby="'nivel-card-' + nivelIndex">
                        <div class="card-body">
                          <!-- produtos -->
                          <template v-if="[0, 1].indexOf(parseInt(nivel.fixo))">
                            <div v-for="(nProduct, nProductIndex) in nivel.niveis" :key="nProductIndex"
                              class="card-body-div">
                              <div class="card-body-inputs">
                                <input :id="'opt-' + nProduct.codigo" type="radio" @click="setNivelProduct(nProduct)"
                                  :name="'nivel_' + nProduct.nivel" :checked="parseInt(nivel.obrigatorio) == 1 &&
                                    nivel.niveis.length == 1
                                    " />
                                <label :for="'opt-' + nProduct.codigo">{{
                                  nProduct.produto.descricao
                                }}</label>
                              </div>
                              <div class="d-flex align-items-center">
                                <!-- Quando os pedidos não são PAGOS pela unidade, são pagos pelos clientes. Então liberamos quantidade -->
                                <div v-if="nProduct.produto.quantity > 0 &&
                                  !configs.has_orders_payments
                                " class="minus-div qty mr-2">
                                  <span @click="
                                    setProductQuantity(nProduct, 'remove')
                                    " class="minus bg-dark">-</span>
                                  <input type="number" onkeydown="return false;" class="minus-input count" name="qty"
                                    :value="nProduct.produto.quantity" min="1" max="100" />
                                  <span @click="setProductQuantity(nProduct, 'add')" class="plus bg-dark">+</span>
                                </div>
                                <!-- se a unidade tiver pedidos pagos, mostrar sempre o valor zerado  -->
                                <div v-if="configs.has_orders_payments">
                                  <span class="price">{{ 0.0 | money }}</span>
                                </div>
                                <div v-else>
                                  <span v-if="nProduct.produto.quantity > 0" class="price">{{
                                    (nProduct.produto.precovenda *
                                      nProduct.produto.quantity)
                                    | money
                                  }}</span>
                                  <span v-else class="price">{{
                                    nProduct.produto.precovenda | money
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </template>
                          <template v-if="parseInt(nivel.obrigatorio) == 0">
                            <div class="card-body-inputs">
                              <input :id="'opt-remove-' + nivel.nivel" type="radio" @click="removeNivelProduct(nivel)"
                                :name="'nivel_' + nivel.nivel" value="0" />
                              <label :for="'opt-remove-' + nivel.nivel">Não, obrigado.</label>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <!--  -->
          <div v-if="niveis && niveis.length > 0 && localities.length > 0" class="modal-body-meal">
            <div class="modal-body-meal-header">
              <h2>Locais de Entrega</h2>
            </div>


            <div class="modal-body-options-element">
              <div class="card-body pt-0">
                <div class="card-body-div">
                  <div class="card-body-inputs d-flex flex-column align-items-start" style="gap: 10px;">
                    <div v-for="(locality, index) in localities" :key="index" class="local-delivery">
                      <input style="position: absolute;" :id="'opt-locality-' + locality.id" type="radio"
                        @click="setPayload({ locality_id: locality.id })" name="locality_id" required />
                      <label :for="'opt-locality-' + locality.id">{{ locality.name }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>





          </div>
          <!--  -->
          <div v-if="niveis && niveis.length > 0 && configs.has_observations" class="modal-body-meal">
            <div class="modal-body-meal-header">
              <h2>Adicione observações</h2>
            </div>
            <div class="modal-body-meal-element">
              <textarea name="observations" v-model="observations" class="form-control" placeholder="escreva aqui..."
                rows="2" />
            </div>
          </div>

          <div v-if="niveis &&
            niveis.length > 0 &&
            !configs.has_orders_payments &&
            totalCart > 0" class="modal-body-payment">
            <div class="modal-body-meal-header">
              <h2>Tipo de pagamento</h2>
            </div>
            <div class="modal-body-options-element">
              <div class="card-body pt-0">
                <div class="card-body-div">
                  <div class="card-body-inputs d-flex flex-column align-items-start" style="gap: 10px;">
                    <div>
                      <input type="radio" id="is_mbway" v-model="paymentType" value="is_mbway"
                        @click="setPaymentMethod('is_mbway')" />
                      <label for="is_mbway">Pagamento MBWAY</label>
                    </div>
                    <div v-if="this.user.balance >= totalCart">
                      <input type="radio" id="is_eurest_balance" v-model="paymentType" value="is_eurest_balance"
                        @click="setPaymentMethod('is_eurest_balance')" />
                      <label for="is_eurest_balance">Pagamento Saldo Eurest: <strong>{{ user.balance | money
                          }}</strong></label>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <form ref="formOrderPayment">
              <div class="modal-body-payment-element">
                <div class="total">
                  <p class="total-text">Total a pagar</p>
                  <p class="total-price">{{ totalCart | money }}</p>
                </div>
                <div class="content-input" v-if="paymentType == 'is_mbway'">
                  <img class="img-fluid" src="../assets/img/schedules/mb-way.png" alt="mb-way" title="mb-way" />
                  <input type="tel" name="mobile" placeholder="Telemóvel" v-model="mobile" maxlength="9" required />
                  <input type="hidden" name="totalCart" :value="parseFloat(totalCart).toFixed(2)" />
                </div>
              </div>
            </form>
          </div>

          <!-- Se tiver pagamento -->
          <button v-if="niveis &&
            niveis.length > 0 &&
            !configs.has_orders_payments &&
            totalCart > 0 &&
            paymentType != null
          " type="button" @click="handlePayment" class="modal-body-btn-confirm">
            Pagamento
          </button>
          <!-- Se não tiver pagto -->
          <button v-if="niveis && niveis.length > 0 && configs.has_orders_payments" type="button" @click="handleSubmit"
            class="modal-body-btn-confirm">
            Confirmar
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      buttonDisabled: false,
      hour_deliveries: [],
      niveis: [],
      paymentType: null,

    };
  },
  computed: {
    ...mapGetters("App", ["configs"]),
    ...mapGetters("Order", [
      "periods",
      "payload",
      "period_menu",
      "orderPeriod",
      "localities",
    ]),
    ...mapGetters("User", ["user", "unit", "minimum_recharge"]),
    mobile: {
      get() {
        return this.payload.mobile ? this.payload.mobile : "";
      },
      set(value) {
        this.setPayload({ mobile: value });
      },
    },
    observations: {
      get() {
        return this.payload.observations ? this.payload.observations : "";
      },
      set(value) {
        this.setPayload({ observations: value });
      },
    },
    totalCart() {
      if ("products" in this.payload) {
        let total = 0;
        this.payload.products.map((item) => {
          total += parseFloat(item.produto.precovenda * item.produto.quantity);
        });

        return total;
      } else {
        return 0;
      }
    },
  },
  methods: {
    ...mapActions("Order", [
      "setPeriodMenu",
      "setPayload",
      "getTotalOrderPeriod",
      "saveOrder",
      "getOrders",
    ]),
    ...mapActions("User", ["callOrderPayment", "callOrderPaymentWithBalance"]),
    refreshPage() {
      document.location.reload();
    },

    async loadHourDelivery(period) {
      if (!period.has_click_active.status) {
        return;
      }

      this.setPeriodMenu({ period_id: period.id, screen: "order" });

      await this.getTotalOrderPeriod();

      this.hour_deliveries = period.hour_deliveries;
    },

    setProductQuantity(nivelProduct, type) {
      //se não há pelo menos um produto selecionado, não faz nada

      if (!this.payload.products || this.payload.products.length == 0)
        return false;

      let validNivel = this.payload.products.find(
        (obj) => obj.nivel == nivelProduct.nivel
      );

      if (!validNivel) return false;

      if (type == "add") {
        if (!nivelProduct.produto.quantity) {
          nivelProduct.produto.quantity = 1;
        } else {
          nivelProduct.produto.quantity++;
        }
      }
      if (type == "remove") {
        if (nivelProduct.produto.quantity > 1) {
          nivelProduct.produto.quantity--;
        }
      }

      let products = this.payload.products.filter(
        (obj) => obj.codigo !== nivelProduct.codigo
      );

      //after remove, add again
      products.push(nivelProduct);
      this.setPayload({ products: products });

      console.log(products);
    },
    setHourDelivery($event, hour) {
      if ($event.target.classList.contains("isDisabled")) {
        return;
      }

      this.setPayload({ hour_delivery: hour.name });
    },
    checkIfAllowHour(hour) {
      if (this.orderPeriod && this.orderPeriod.length > 0) {
        if (hour.max_order && parseInt(hour.max_order) > 0) {
          this.orderPeriod.map((el, index) => {
            if (
              hour.name == el.hour_delivery &&
              parseInt(el.total) >= parseInt(hour.max_order)
            ) {
              return true;
            }
          });
        }
      }

      return false;
    },

    setProductSelect(product) {
      this.setPayload({ menu_product_id: product.id });

      if (product.menu_levels) {
        this.niveis = product.menu_levels;

        this.niveis.filter((n) => {
          if (n.niveis.length == 1 && n.obrigatorio == 1) {
            this.setNivelProduct(n.niveis[0]);
            this.setProductQuantity(n.niveis[0], "add");
          }
        });
      } else {
        // Handle the case where product.menu_levels is null or undefined
      }
    },

    async findNivel(nivel) {
      return this.niveis.find((n) => n.nivel == nivel);
    },

    async findProductNivel(nivel) {
      let response = this.payload.products.find((n) => n.nivel == nivel);

      return response;
    },

    async setNivelProduct(nivelProduct) {
      let nivelMain = await this.findNivel(nivelProduct.nivel);
      let products = [];

      if ("products" in this.payload) {
        await this.removeNivelProduct(nivelMain);
        products = this.payload.products;
      }

      let productFound = products.find(
        (p) => p.codigo == nivelProduct.codigo && p.nivel == nivelProduct.nivel
      );

      if (!productFound) {
        console.log("não encontrou", nivelProduct);
        nivelProduct.produto.quantity = 1;
        products.push(nivelProduct);
        this.setPayload({ products: products });
      }
    },

    async removeNivelProduct(nivel) {
      let payload = this.payload;

      if ("products" in payload) {
        let newPayload = [];
        await payload.products.map(async (n) => {
          if (n.nivel != nivel.nivel) {
            newPayload.push(n);
          } else {
            n.produto.quantity = 0;
          }
        });

        this.setPayload({ products: newPayload });
      }
      console.log("payload after remove tudo", this.payload.products);
    },

    validProductsOrderRequired() {
      const self = this;
      return new Promise((resolve, reject) => {
        (async () => {
          if (!("products" in self.payload)) {
            reject("É necessário selecionar uma opção de prato");
            return;
          }

          if (self.localities.length > 0 && !("locality_id" in self.payload)) {
            reject("É necessário selecionar um local de entrega");
            return;
          }

          await self.niveis.map(async (item) => {
            if (item.niveis && item.niveis.length > 0) {
              if (
                parseInt(item.obrigatorio) == 1 &&
                item.niveis.length == 1 &&
                item.niveis[0].produto?.quantity == 0
              ) {
                self.setNivelProduct(item);
              } else {
                let productFound = await self.findProductNivel(item.nivel);
                let inputRemove = document.querySelector(
                  `#opt-remove-${item.nivel}`
                );
                // console.log(inputRemove, inputRemove.checked)
                if (!productFound && (!inputRemove || !inputRemove.checked)) {
                  reject(`É necessário selecionar a opção ${item.descricao}`);
                  return;
                }
              }
            }
          });

          resolve();
        })();
      });
    },
    async handleSubmit() {
      try {
        let filteredNiveis = this.removeDuplicateds(this.niveis);
        this.niveis = filteredNiveis;

        await this.validProductsOrderRequired();
        await this.saveOrder();

        this.hour_deliveries = [];
        this.niveis = [];
        await this.getOrders({
          date: [
            this.$moment().format("YYYY-MM-DD"),
            this.$moment().add("days", 30).format("YYYY-MM-DD"),
          ],
        });
      } catch (err) {
        this.$alert(err);
      }
    },
    setPaymentMethod(method) {
      this.setPayload({ payment_method: method });
    },
    async handlePayment() {
      let formData = this.$root.getAllData(this.$refs.formOrderPayment);

      formData.unit_id = this.unit;
      formData.order_id = null;
      if (this.$refs.formOrderPayment.checkValidity()) {
        try {
          await this.validProductsOrderRequired();
          const response = await this.saveOrder();
          console.log("order criado: ", response.data.data);
          this.hour_deliveries = [];
          this.niveis = [];
          await this.getOrders({
            date: [
              this.$moment().format("YYYY-MM-DD"),
              this.$moment().add("days", 30).format("YYYY-MM-DD"),
            ],
          });
          if (response.data.data.id) {
            formData.order_id = response.data.data.id;
          }
          /*
          * Se o user tiver saldo suficiente, o pedido é criado e o pagamento é feito usando este saldo. Caso contrário, usa o MBWAY.
          */
          if (this.paymentType == "is_eurest_balance") {
            await this.callOrderPaymentWithBalance(formData);
            this.$alert("Pedido pago com sucesso!");
          } else if (this.paymentType == "is_mbway") {
            await this.callOrderPayment(formData);
            //temos q colocar a msg do response do mbway
          }
        } catch (err) {
          this.$alert(err);
        }
      } else {
        this.$refs.formOrderPayment.reportValidity();
      }
    },

    removeDuplicateds(arr) {
      return arr.filter((nivel) => {
        if (nivel.niveis.length > 0) {
          // check if there is any duplicated codigo
          let codigos = nivel.niveis.map((nivel) => nivel.codigo);
          let duplicated = codigos.filter((v, i, a) => a.indexOf(v) !== i);

          if (duplicated.length > 0) {
            // remove duplicated codigo
            duplicated.forEach((codigo) => {
              nivel.niveis = nivel.niveis.filter(
                (nivel) => nivel.codigo !== codigo
              );
            });
          }

          return nivel;
        } else {
          return true;
        }
      });
    },
  },

};
</script>
<style scoped>
.local-delivery [type="radio"]:checked+label:before,
.local-delivery [type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #e2e2e2;
  border-radius: 100%;
  background: #fff;
}

button:disabled,
button[disabled] {
  border: 1px solid #cccccc;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed !important;
  opacity: 0.5;
  text-decoration: none;
}

.linkActive {
  background: #005aa1 !important;
  color: white !important;
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed !important;
  opacity: 0.5;
  text-decoration: none;
}

.qty {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.qty .count {
  color: #000;
  display: inline-block;
  vertical-align: top;
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;
  padding: 0 2px;
  min-width: 35px;
  text-align: center;
}

.qty .plus {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  color: white;
  width: 24px;
  height: 24px;
  font: 24px/1 Arial, sans-serif;
  text-align: center;
  border-radius: 50%;
}

.qty .minus {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  color: white;
  width: 24px;
  height: 24px;
  font: 24px/1 Arial, sans-serif;
  text-align: center;
  border-radius: 50%;
  background-clip: padding-box;
}

.minus:hover {
  background-color: #717fe0 !important;
}

.plus:hover {
  background-color: #717fe0 !important;
}

/*Prevent text selection*/
/* span{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
} */
input {
  border: 0;
  width: 2%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:disabled {
  background-color: white;
}

@media screen and (max-width: 768px) {
  .custom-heigth {
    align-items: center !important;
    height: 65px !important;
    font-size: 13px;
  }
}

.custom-heigth {
  align-items: center;
  justify-content: center;
  height: 20px;
}
</style>
