import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import mixin from './mixins';
//
import VueSimpleAlert from "vue-simple-alert";
import axios from 'axios';
import VueMobileDetection from 'vue-mobile-detection'
import VueCurrencyFilter from 'vue-currency-filter'
import VCalendar from 'v-calendar';
import Skeleton from 'vue-loading-skeleton'; 

//
import 'bootstrap';
import './assets/css/styles.css';

axios.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem('apprest_token');
    config.headers.Authorization =  token;

    return config;
});

Vue.config.productionTip = false;

Vue.use(Skeleton);
Vue.use(require('vue-moment'));
Vue.use(VueMobileDetection)
Vue.use(VueSimpleAlert);
Vue.use(VueCurrencyFilter,
{
  name: 'money',
  symbol : '€',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'back',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
});

Vue.use(VCalendar);

new Vue({
	router,
	store,
	mixins: [mixin],
	render: h => h(App),
}).$mount('#app')
