<template>
	<div class="modal modal-carry-wallet fade in" tabindex="-1" role="dialog" id="modal-payment-process">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<a href="javascript:;" class="modal-header" data-dismiss="modal">
					<h2 class="modal-title">Pagamento em processo</h2>
				</a>
				<div class="modal-body">
					<div class="modal-body-carry-wallet">
						<div v-if="payment.status_payment_id == 2" align="center">
							<img src="@/assets/loading-payment.gif" style="height: 178px">
							<p>
								estamos processando o pagamento...
							</p>
						</div>
						<div v-else align="center">
							<countdown-payment :time_limit="(payment.time_limit_payment - payment.countdown)">
							</countdown-payment>
							<p>
								Confirme a transação em seu app MBWay
							</p>
						</div>
					</div>
				</div>
			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>
</template>
<script>
import CountdownPayment from './Countdown';
import Pusher from 'pusher-js';
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'ModalProcess',
	data() {
		return {
			subscribeOn: false,
		}
	},
	components: {
		CountdownPayment,
	},
	computed: {
		...mapGetters('User', ['user', 'payment']),
		...mapGetters('App', ['pusher']),
	},
	methods: {
		...mapActions('User', ['setPayment', 'checkPaymentOpened', 'setInfo']),
		subscribe() {
			const self = this;

			if (this.payment.token in this.pusher.channels.channels) {
				return false;
			}

			//   self.subscribeOn = true;
			//   let pusher = new Pusher('1f2ce5618fcb2fccb9e9', { cluster: 'eu' })
			this.pusher.subscribe(this.payment.token);

			this.pusher.bind('payment', data => {
				console.log("Receive Pusher", data);
				let paymentData = data.data;
				self.setPayment(paymentData);

				self.checkStatePayment();
			});
		},
		checkStatePayment() {
			if (this.payment.status_payment_id == 2) {
				this.paymentSuccess();
			} else if (this.payment.status_payment_id != 1) {
				this.$store.dispatch('App/setModalAlert', {
					closeModal: 'modal-payment-process',
					type: 'payment-error',
					open: true,
				});

				this.setPayment({});
			}
		},
		paymentSuccess() {
			setTimeout(() => {
				this.$store.dispatch('App/setModalAlert', {
					closeModal: 'modal-payment-process',
					type: 'payment-success',
					open: true,
				});

				this.setPayment({});
			}, 3000)
		},
		openViewModal() {
			if (this.payment.open) {
				this.$root.closeModal('modal-carry-wallet');
				this.$root.openModal('modal-payment-process');

				this.subscribe();
				this.checkStatePayment();
			}
		}
	},
	watch: {
		// payment(pay){
		// 	console.log("CHANGE PAY", pay)
		// 	if(pay && pay.status_payment_id != 1){
		// 		this.setInfo();
		// 	}
		// }
	},
	created() {
	},
	mounted() {
		this.openViewModal();

		this.$on("countdown", async function (finish) {
			if (finish) {
				await this.checkPaymentOpened();
			}
		});
	}
}
</script>