import axios from 'axios';
export default {
    async getInfo() {
        return await axios.get('/api/v1/user/get-info');
    },
    async checkPaymentOpened() {
        return await axios.get('/api/v1/user/check-transaction');
    },
    async getTransactions() {
        return await axios.get('/api/v1/user/get-transactions');
    },
    async login(params) {
        return await axios.post('/api/v1/user/login', params);
    },
    async logout(params) {
        return await axios.post('/api/v1/user/logout', params);
    },
    async recoverPass(params) {
        return await axios.post('/api/v1/user/recover-pass', params);
    },
    async recoverPassEmail(params) {
        return await axios.post('/api/v1/user/recover-pass-email', params);
    },
    async getRecoverInfo(params) {
        return await axios.post('/api/v1/user/get-recover-info', params);
    },
    async updateAccount(params) {
        return await axios.post('/api/v1/user/update-account', params);
    },
    async addBalanceAccount(params) {
        return await axios.post('/api/v1/user-payment/add-balance-account', params);
    },
    async doOrderPayment(params) {
        return await axios.post('/api/v1/user-payment/do-order-payment', params);
    },
    async doOrderPaymentWithBalance(params) {
        return await axios.post('/api/v1/user-payment/do-order-payment-with-balance', params);
    },
    async setBooks(params) {
        return await axios.post('/api/v1/restaurant/save-books', params);
    },
    async getBooks(params) {
        return await axios.post('/api/v1/restaurant/get-books', params);
    },
    async getMinimumRecharge(params) {
        return await axios.post('/api/v1/user/get-minimum-recharge', params);
    }
}