<template>
  <div
    class="modal modal-data-person fade in"
    tabindex="-1"
    role="dialog"
    id="modal-email-alert"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title pr-5">Atualize os seus dados</h2>
          <button
            @click="$root.closeModal('modal-email-alert')"
            data-dismiss="modal"
            type="button"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            
             <div class="modal-body-data-person">
              <div class="content-input">
                <label> Prezado utilizador, verificamos que seu email não está preenchido.</label>
                <label>Atualize na opção <strong>Menu -> Dados Pessoais</strong></label>
             </div> 
             </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },

  name: "ModalEmailAlert",
  computed: {
    ...mapGetters("User", ["user"]),
  },
  methods: {},

  mounted() {},
};
</script>
<style scoped>
.has_required {
  text-decoration: line-through;
  color: #689868;
}
</style>