<template>
  <div
    class="modal modal-data-person fade in"
    tabindex="-1"
    role="dialog"
    id="modal-first-login"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title pr-5">Atualize os seus dados</h2>
          <button
            v-if="isUserFirstLogin()"
            @click="
              isUserFirstLogin()
                ? doLogout()
                : $root.closeModal('modal-first-login')
            "
            data-dismiss="modal"
            type="button"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form ref="formFirstLogin" @submit.prevent="handleSubmit">
            <input type="hidden" name="modal_origin" :value="modalOrigin" />
            <div class="modal-body-data-person">
              <div class="content-input">
                <label> Telemóvel </label>
                <!-- <input
                  type="tel"
                  name="mobile"
                  placeholder=""
                  maxlength="9"
                  required
                /> -->
                <vue-tel-input
                  class="py-3"
                  @validate="validateInput"
                  v-model="mobile"
                  mode="international"
                  :inputOptions="options"
                  defaultCountry="PT"
                  :validCharactersOnly="true"
                ></vue-tel-input>
                <input required type="hidden" name="mobile_ddi" :value="mobile_ddi" />
                <input required type="hidden" name="mobile_raw" :value="mobile_raw" />
                <small class="form-text text-danger">Este telemóvel será usado para recuperar sua password.</small>
              </div>
              <div class="content-input">
                <label> Nova Password </label>
                <input
                  v-model="password"
                  type="password"
                  name="password"
                  value=""
                  placeholder=""
                  required
                />

                <small
                  v-if="validPassword == false"
                  id="passwordHelp"
                  class="form-text text-danger"
                  >A password deve conter:
                  <span :class="has_minimum_lenth ? 'has_required' : ''"
                    >Mínimo 8 caracteres</span
                  >,
                  <span :class="has_uppercase ? 'has_required' : ''"
                    >pelo menos uma maiúscula</span
                  >,
                  <span :class="has_number ? 'has_required' : ''"
                    >pelo menos 1 número</span
                  >, e
                  <span :class="has_special ? 'has_required' : ''"
                    >pelo menos 1 caracter especial</span
                  ></small
                >
                <small v-else class="form-text text-success">
                  Password válida!
                </small>
              </div>
              <div class="content-input">
                <label> Confirmação Password </label>
                <input type="password" name="password_confirmation" required />
              </div>
              <div class="content-input">
                <label> Email </label>
                <input type="email" name="email" required />
                <small class="form-text text-danger">Este email será usado para recuperar sua password.</small>
              </div>
              <div class="content-input">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="accepted_terms"
                    id="exampleCheck1"
                    required="true"
                    value="1"
                    style="width: 15px; height: 15px"
                  />
                  <label class="form-check-label" for="exampleCheck1"
                    >Aceito os
                    <a
                      href="javascript:;"
                      @click="$root.openModal('modal-rgpd')"
                      >Termos e Condições</a
                    >
                    que gerem a plataforma de reservas Eurest</label
                  >
                </div>
              </div>
            </div>
            <button
              type="submit"
              class="modal-body-btn-record-changes"
              :disabled="btnDisabled"
              :style="{
                backgroundColor: btnDisabled ? '#ccc' : '',
                opacity: btnDisabled ? 0.5 : 1,
                cursor: btnDisabled ? 'not-allowed' : 'pointer',
              }"
            >
              Gravar Alterações
            </button>
          </form>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
export default {
  data() {
    return {
      modalOrigin: "modal_first_login",
      btnDisabled: false,
      mobile: "",
      mobile_ddi: "",
      mobile_raw: "",
      options: {
        placeholder: "Digite um telemóvel",
        maxlength: 25,
        showDialCode: true,
        required: true,
        autocomplete: "on",
        name: "mobile",
      },
      //password real time feedback
      password: "",
      has_minimum_lenth: false,
      has_number: false,
      has_uppercase: false,
      has_special: false,
      validPassword: false,
    };
  },
  components: {
    VueTelInput,
  },
  name: "ModalFirstLogin",
  computed: {
    ...mapGetters("User", ["user"]),
  },
  methods: {
    ...mapActions("User", ["updateAccount", "logout"]),
    async doLogout() {
      const user = this.user;
      const response = await this.logout(user);
      if (response.data.logout == true) {
        this.$root.closeModal("modal-first-login");
        this.$root.closeModal("modal-change-password");
        this.$router.push({
          name: "login",
          params: { unit: response.data.unitName },
        });
      }
    },
    async handleSubmit() {
      let formData = this.$root.getAllData(this.$refs.formFirstLogin);
      if (this.$refs.formFirstLogin.checkValidity()) {
        const response = await this.updateAccount(formData);
        this.$root.closeModal("modal-first-login");
        this.$refs.formFirstLogin.reset();
        this.validPassword = false;
      } else {
        this.$refs.formFirstLogin.reportValidity();
      }
    },
    validateInput(obj) {
      this.btnDisabled = !obj.valid || false;
      if (obj.valid == true) {
        this.mobile_raw = obj.nationalNumber;
        this.mobile_ddi = obj.countryCallingCode;
      }
    },

    isUserFirstLogin() {
      if (Object.keys(this.user).length > 0 && (!this.user.accepted_terms || !this.user.mobile || !this.user.email)) {
        return true;
      }
      return false;
    },
  },
  watch: {
    user(promise) {
      if (Object.keys(promise).length > 0) {
        this.isUserFirstLogin();
      }
    },
    password() {
      this.has_minimum_lenth = this.password.length > 8;
      this.has_number = /\d/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_special = /[!@#$%^&*)(+=._-]/.test(this.password);
      if (
        this.has_minimum_lenth &&
        this.has_number &&
        this.has_uppercase &&
        this.has_special
      ) {
        this.validPassword = true;
        this.btnDisabled = false;
      } else {
        this.validPassword = false;
        this.btnDisabled = true;
      }
    },
  },
  mounted() {
    this.isUserFirstLogin();
  },
};
</script>
<style scoped>
.has_required {
  text-decoration: line-through;
  color: #689868;
}
</style>