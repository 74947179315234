import Unit from '@/models/Unit';

export default {
  /*
  * Pega o email da unidade pelo mneumônico code (custom_name)
  */
  async getUnitByMneumonic(context, payload) {
    try {
      const response = await Unit.getUnitByMneumonic(payload);
      const res = response.data.data;
      context.commit('setUnit', res);
      return res;
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
      throw error;
    }
  },
};