export default {
  setInfo(state, payload) {
    state.user = payload;
  },
  setTransactions(state, payload) {
    state.transactions = payload;
  },
  setPayment(state, payload) {
    let params = {
      open: false,
      key: (new Date().getTime()),
    };
    if (Object.keys(payload).length > 0) {
      params.open = true;
      params = { ...params, ...payload }
    }
    state.payment = params;
  },
  setToken(state, payload) {
    sessionStorage.setItem('apprest_token', payload)
    state.token = payload;
  },
  setUnit(state, payload) {
    sessionStorage.setItem('eurest_unit', payload)
    state.unit = payload;
  },
  setMinimumRecharge(state, payload) {
    sessionStorage.setItem('minimum_recharge', payload)
    state.minimum_recharge = payload;
  }
};