import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

async function protectedPage(to, from, next) {
    try {
        let user = store.getters['User/user'];
        let userKeysLength = Object.keys(user).length;

        if (userKeysLength === 0) {
            user = await store.dispatch('User/setInfo');
            userKeysLength = Object.keys(user).length;
        }

        if (userKeysLength > 0) {
            next();
        } else {
            next({ name: "login" });
        }
    } catch (e) {
        next({ name: "login" });
    }
}

const baseRoutes = [
	{
		path: '/',
		name: 'home',
		beforeEnter(to, from, next) {
			protectedPage(to, from, next);
		},
		component: () => import(/* webpackChunkName: "home" */'@/views/Home.vue'),
	},
	{
		path: '/reservas',
		name: 'books',
		beforeEnter(to, from, next) {
			protectedPage(to, from, next);
		},
		component: () => import(/* webpackChunkName: "books" */'@/views/Books.vue'),
	},
	{
		path: '/pedidos',
		name: 'orders',
		beforeEnter(to, from, next) {
			protectedPage(to, from, next);
		},
		component: () => import(/* webpackChunkName: "books" */'@/views/Orders.vue'),
	},
	{
		path: '/login/:unit?',
		name: 'login',
		component: () => import(/* webpackChunkName: "login" */'@/views/Login.vue'),
	},
	{
		path: '/cartao/:unit',
		name: 'cartao',
		component: () => import(/* webpackChunkName: "login" */'@/views/Cartao.vue'),
	},
	{
		path: '/recover-pass/:unit?',
		name: 'recover-pass',
		component: () => import(/* webpackChunkName: "recover-pass" */'@/views/RecoverPass.vue'),
	},
	{
		path: '*',
		name: '404',
		component: () => import(/* webpackChunkName: "404" */'@/views/404.vue'),
	},
];

const routes = baseRoutes;

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior(to, from, savedPosition) {
		return { x: 0, y: 0 };
	}
});

export default router;