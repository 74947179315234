export default {
  setBooks(state, payload) {
    state.books = payload;
  },
  loadingBook(state, payload) {
    state.loadingBook = payload;
  },
  setPeriodsModal(state, payload) {
    state.periods = payload;
  },
  setPeriodMenu(state, payload) {
    state.period_menu = payload;
  },
  setPayload(state, value) {
    state.payload = Object.assign({}, state.payload, value)
  },
  clearPayload(state) {
    state.payload = {};
    state.period_menu = {};
    state.periods = {};
  },
  setAllMenusByUnit(state, payload) {
    state.allMenusByUnit = payload;
  }
};