<template>
  <div id="app">
    <loading :active.sync="loading" :is-full-page="true"></loading>

    <transition name="fade" mode="out-in">
      <router-view />
    </transition>

    <menu-navigation></menu-navigation>

    <alert-error v-if="error && error.open" :key="error.key"></alert-error>
    <alert-message v-if="alert && alert.open" :key="alert.key"></alert-message>
    <modal-alert
      v-if="modalAlert && modalAlert.open"
      :key="modalAlert.key"
    ></modal-alert>

    <modal-schedule />
    <modal-schedule-orders />
    <modal-profile />
    <modal-password />
    <modal-first-login />
    <modal-rgpd />
    <modal-payment />
    <modal-processing v-if="payment.open" :key="payment.key" />
    <modal-transaction />
    <modal-email-alert />
    <!--  -->
    <div :class="{ backdrop__bar: true, 'd-none': !backdrop }"></div>
    <!--  -->
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import AlertError from "@/components/AlertError";
import AlertMessage from "@/components/Alert";
import MenuNavigation from "@/components/MenuNavigation";
import ModalSchedule from "@/components/ModalSchedule";
import ModalAlert from "@/components/ModalAlert";
import ModalProfile from "@/components/ModalProfile";
import ModalPassword from "@/components/ModalPassword";
import ModalFirstLogin from "@/components/ModalFirstLogin";
import ModalRgpd from "@/components/ModalRgpd";
import ModalPayment from "@/components/ModalPayment";
import ModalScheduleOrders from "@/components/ModalScheduleOrders";
import ModalProcessing from "@/components/ModalProcessing";
import ModalTransaction from "@/components/ModalTransactions";
import ModalEmailAlert from "@/components/ModalEmailAlert";
import { mapGetters, mapActions } from "vuex";
import Pusher from "pusher-js";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "App",
  components: {
    Loading,
    AlertError,
    AlertMessage,
    MenuNavigation,
    ModalSchedule,
    ModalAlert,
    ModalPassword,
    ModalProfile,
    ModalFirstLogin,
    ModalRgpd,
    ModalPayment,
    ModalScheduleOrders,
    ModalProcessing,
    ModalTransaction,
    ModalEmailAlert
  },
  computed: {
    ...mapGetters("App", [
      "loading",
      "error",
      "backdrop",
      "alert",
      "modalAlert",
    ]),
    ...mapGetters("User", ["user", "payment"]),
  },
  methods: {
    ...mapActions("App", ["setPusher"]),
    isUserFirstLogin() {
      if (Object.keys(this.user).length > 0 && (!this.user.accepted_terms || !this.user.mobile || !this.user.email)) {
        this.$root.openModal("modal-first-login");
      }
    },
    checkNeedToUpdatePassword() {
      //verify if today's date is higher pw_updated_at  in 90 days
      if (Object.keys(this.user).length > 0 && this.user.pw_updated_at) {
        let today = new Date();
        let pw_updated_at = new Date(this.user.pw_updated_at);
        let diff = today - pw_updated_at;
        let days = Math.floor(diff / (1000 * 60 * 60 * 24));
		//se tiver mais que 3 meses sem alterar a password abre o modal
        if (days >= 90) {
          this.$root.openModal("modal-change-password");
        }
      }
    },
  },
  watch: {
    user(promise) {
      if (Object.keys(promise).length > 0) {
        this.isUserFirstLogin();
        this.checkNeedToUpdatePassword();
      }
    },
  },
  mounted() {
    this.isUserFirstLogin();
    this.checkNeedToUpdatePassword();
    var pusher = new Pusher("1f2ce5618fcb2fccb9e9", { cluster: "eu" });

    this.setPusher(pusher);
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
/* CSS REMOVE BORDER FROM VUE TEL INPUT */
.vue-tel-input, .vue-tel-input:focus {
  border: none !important;
  box-shadow: none !important;
}
</style>