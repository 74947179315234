<template>
  <div class="modal modal-schedule fade in show" tabindex="-1" role="dialog" id="modal-schedule">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <a @click="$root.closeModal('modal-schedule')" class="modal-header" data-dismiss="modal">
          <svg xmlns="http://www.w3.org/2000/svg" width="15.153" height="13.639" viewBox="0 0 15.153 13.639">
            <path
              d="M8.27,107.648l-2.8-2.8h8.66a1.026,1.026,0,0,0,1.026-1.026v-1.026a1.026,1.026,0,0,0-1.026-1.026H5.467l2.8-2.8a1.026,1.026,0,0,0,0-1.451l-.726-.726a1.026,1.026,0,0,0-1.451,0L.3,102.579a1.026,1.026,0,0,0,0,1.451l5.794,5.794a1.026,1.026,0,0,0,1.451,0l.726-.726a1.026,1.026,0,0,0,0-1.451Z"
              transform="translate(0.001 -96.485)" fill="#005aa1" />
          </svg>
          <h2 class="modal-title">Reserva</h2>
        </a>
        <div class="modal-body">
          <div class="modal-body-period">
            <div class="modal-body-period-header">
              <h2>Selecionar período</h2>
            </div>

            <div class="modal-body-period-element d-block">
              <div v-for="(period, periodIndex) in periods" :key="periodIndex" class="w-100"
                style="margin-left: -15px !important;">
                <div class="container-fluid d-flex align-items-center justify-content-left">
                  <div class="row">
                    <div class="col d-flex justify-content-center">
                      <a @click="checkClickPeriod(period)" :key="periodIndex" :disabled="period.has_max_books_amount_by_period == true ||
                        !period.has_click_active.status
                        " :class="{
                          isDisabled:
                            period.has_max_books_amount_by_period == true ||
                            !period.has_click_active.status,
                          linkActive:
                            period.has_max_books_amount_by_period == false &&
                            period.id == payload.period_id,
                        }">
                        {{ period.name }}
                      </a>
                    </div>
                  </div>
                  <div class="row w-100">
                    <div class="col text-center">
                      <div class="alert alert-primary custom-heigth d-flex" role="alert">
                        <small>{{ period.has_click_active.message }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="modal-body-period-element">
              <template v-for="(period, periodIndex) in periods">
  
              </template>
</div> -->
          </div>
          <div v-if="Object.keys(period_menu).length > 0" class="modal-body-meal">
            <div class="modal-body-meal-header">
              <h2>Escolha a refeição</h2>
            </div>
            <div class="modal-body-meal-element menu-gap">
              <div v-for="(periodMenu, periodMenuIndex) in period_menu.menus" :key="periodMenuIndex" class="w-100"
                style="margin-left: -15px !important;">
                <div class="container-fluid d-flex align-items-center justify-content-left">
                  <div class="row">
                    <div class="col d-flex justify-content-center">
                      <a @click="checkClickProduct(periodMenu)" :class="{
                        isDisabled: periodMenu.has_max_books_amount == true,
                        links: true,
                      }" :disabled="periodMenu.has_max_books_amount == true">
                        <div :class="{
                          'links-icon': true,
                          isDisabled: periodMenu.has_max_books_amount == true,
                          linkActive:
                            periodMenu.has_max_books_amount == false &&
                            periodMenu.id == payload.menu_product_id,
                        }">
                          <img class="img-fluid" :src="periodMenu.image_color" />
                        </div>

                      </a>
                    </div>
                  </div>
                  <div class="row w-100">
                    <div class="col text-center">
                      <div class="w-100 d-flex align-center-mobile">
                        <p class="links-text">{{ periodMenu.name }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="button" :disabled="!payload.menu_product_id" @click="handleSubmit"
            class="modal-body-btn-confirm">
            Confirmar
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      click_product_id: null,
    };
  },

  computed: {
    ...mapGetters("Book", ["periods", "payload", "period_menu"]),
  },
  methods: {
    ...mapActions("Book", [
      "setPeriodMenu",
      "setPayload",
      "saveBook",
      "getBooks",
      "getMenu",
    ]),
    checkClickPeriod(period) {
      console.log(period, period.has_click_active.status);
      if (
        period.has_max_books_amount_by_period == true ||
        !period.has_click_active.status
      ) {
        return;
      }
      this.setPeriodMenu({ period_id: period.id, screen: "book" });
    },
    checkClickProduct(product) {
      console.log(product, product.has_max_books_amount);
      if (product.has_max_books_amount == true) {
        return;
      }
      this.setPayload({ menu_product_id: product.id });
      this.click_product_id = product.menu_id; //pegamos o id do menu do produto
      console.log("click_product_id", this.click_product_id);
    },
    async handleSubmit() {
      let menuResponse = await this.getMenu({ menu_id: this.click_product_id });
      let menu = menuResponse.data;

      //se tem limite não cria a reserva
      if (menu && menu.reached_limit == true) {
        console.log(
          `Menu ${this.click_product_id} chegou no limite das reservas.`
        );
        return;
      }
      //cria reserva
      await this.saveBook();
      await this.getBooks({
        date: [
          this.$moment().format("YYYY-MM-DD"),
          this.$moment().add("days", 30).format("YYYY-MM-DD"),
        ],
      });
    },
  },
  mounted() { },
};
</script>
<style scoped>
button:disabled,
button[disabled] {
  border: 1px solid #cccccc;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed !important;
  opacity: 0.5;
  text-decoration: none;
}

.linkActive {
  background: #005aa1 !important;
  color: white !important;
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed !important;
  opacity: 0.5;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .custom-heigth {
    align-items: center !important;
    height: 65px !important;
    font-size: 13px;
  }

  .align-center-mobile {
    justify-content: center !important;
  }
}

.custom-heigth {
  align-items: center;
  justify-content: center;
  height: 20px;

}

.menu-gap {
  gap: 15px;
}
</style>
