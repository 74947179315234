var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal modal-schedule fade in show",attrs:{"tabindex":"-1","role":"dialog","id":"modal-schedule"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('a',{staticClass:"modal-header",attrs:{"data-dismiss":"modal"},on:{"click":function($event){return _vm.$root.closeModal('modal-schedule')}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"15.153","height":"13.639","viewBox":"0 0 15.153 13.639"}},[_c('path',{attrs:{"d":"M8.27,107.648l-2.8-2.8h8.66a1.026,1.026,0,0,0,1.026-1.026v-1.026a1.026,1.026,0,0,0-1.026-1.026H5.467l2.8-2.8a1.026,1.026,0,0,0,0-1.451l-.726-.726a1.026,1.026,0,0,0-1.451,0L.3,102.579a1.026,1.026,0,0,0,0,1.451l5.794,5.794a1.026,1.026,0,0,0,1.451,0l.726-.726a1.026,1.026,0,0,0,0-1.451Z","transform":"translate(0.001 -96.485)","fill":"#005aa1"}})]),_c('h2',{staticClass:"modal-title"},[_vm._v("Reserva")])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"modal-body-period"},[_vm._m(0),_c('div',{staticClass:"modal-body-period-element d-block"},_vm._l((_vm.periods),function(period,periodIndex){return _c('div',{key:periodIndex,staticClass:"w-100",staticStyle:{"margin-left":"-15px !important"}},[_c('div',{staticClass:"container-fluid d-flex align-items-center justify-content-left"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex justify-content-center"},[_c('a',{key:periodIndex,class:{
                          isDisabled:
                            period.has_max_books_amount_by_period == true ||
                            !period.has_click_active.status,
                          linkActive:
                            period.has_max_books_amount_by_period == false &&
                            period.id == _vm.payload.period_id,
                        },attrs:{"disabled":period.has_max_books_amount_by_period == true ||
                        !period.has_click_active.status},on:{"click":function($event){return _vm.checkClickPeriod(period)}}},[_vm._v(" "+_vm._s(period.name)+" ")])])]),_c('div',{staticClass:"row w-100"},[_c('div',{staticClass:"col text-center"},[_c('div',{staticClass:"alert alert-primary custom-heigth d-flex",attrs:{"role":"alert"}},[_c('small',[_vm._v(_vm._s(period.has_click_active.message))])])])])])])}),0)]),(Object.keys(_vm.period_menu).length > 0)?_c('div',{staticClass:"modal-body-meal"},[_vm._m(1),_c('div',{staticClass:"modal-body-meal-element menu-gap"},_vm._l((_vm.period_menu.menus),function(periodMenu,periodMenuIndex){return _c('div',{key:periodMenuIndex,staticClass:"w-100",staticStyle:{"margin-left":"-15px !important"}},[_c('div',{staticClass:"container-fluid d-flex align-items-center justify-content-left"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex justify-content-center"},[_c('a',{class:{
                        isDisabled: periodMenu.has_max_books_amount == true,
                        links: true,
                      },attrs:{"disabled":periodMenu.has_max_books_amount == true},on:{"click":function($event){return _vm.checkClickProduct(periodMenu)}}},[_c('div',{class:{
                          'links-icon': true,
                          isDisabled: periodMenu.has_max_books_amount == true,
                          linkActive:
                            periodMenu.has_max_books_amount == false &&
                            periodMenu.id == _vm.payload.menu_product_id,
                        }},[_c('img',{staticClass:"img-fluid",attrs:{"src":periodMenu.image_color}})])])])]),_c('div',{staticClass:"row w-100"},[_c('div',{staticClass:"col text-center"},[_c('div',{staticClass:"w-100 d-flex align-center-mobile"},[_c('p',{staticClass:"links-text"},[_vm._v(_vm._s(periodMenu.name))])])])])])])}),0)]):_vm._e(),_c('button',{staticClass:"modal-body-btn-confirm",attrs:{"type":"button","disabled":!_vm.payload.menu_product_id},on:{"click":_vm.handleSubmit}},[_vm._v(" Confirmar ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-body-period-header"},[_c('h2',[_vm._v("Selecionar período")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-body-meal-header"},[_c('h2',[_vm._v("Escolha a refeição")])])
}]

export { render, staticRenderFns }