<template>
	<div class="modal modal-moviment-account fade in" tabindex="-1" role="dialog" id="modal-moviment-account">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<a class="modal-header" @click="$root.closeModal('modal-moviment-account')">
					<svg xmlns="http://www.w3.org/2000/svg" width="15.153" height="13.639" viewBox="0 0 15.153 13.639">
						<path
							d="M8.27,107.648l-2.8-2.8h8.66a1.026,1.026,0,0,0,1.026-1.026v-1.026a1.026,1.026,0,0,0-1.026-1.026H5.467l2.8-2.8a1.026,1.026,0,0,0,0-1.451l-.726-.726a1.026,1.026,0,0,0-1.451,0L.3,102.579a1.026,1.026,0,0,0,0,1.451l5.794,5.794a1.026,1.026,0,0,0,1.451,0l.726-.726a1.026,1.026,0,0,0,0-1.451Z"
							transform="translate(0.001 -96.485)" fill="#005aa1" />
					</svg>
					<h2 class="modal-title">Movimentos de conta</h2>
				</a>
				<div class="modal-body">
					<div v-for="(trans, transIndex) in transactions" :key="transIndex" class="requests-default">
						<div class="requests-default-elements">
							<p class="date">{{ trans.created_at | moment('DD/MM/YYYY') }}</p>
							<p class="dish">{{ trans.description }}</p>
						</div>
						<div class="requests-default-elements">
							<div v-if="!trans.biip_transaction">
								<p v-if="trans.type_action == 'add'" class="price">
									+ {{ trans.amount | money }}
								</p>
								<p v-else class="price" style="color: red;">
									- {{ trans.amount | money }}
								</p>
							</div>
							<div v-else>
								<p v-if="trans.type_action == 'add'" class="price">
									+ {{ trans.biip_transaction.price | money }}
								</p>
								<p v-else class="price" style="color: red;">
									- {{ trans.biip_transaction.price | money }}
								</p>
							</div>

						</div>
					</div>

				</div>
			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	computed: {
		...mapGetters('User', ['transactions']),
	},
	mounted() {
		//console.log('transacoes', this.transactions);
	},
}
</script>
<style>
.badge-error {
	color: #fff;
	background-color: #dc3545;
}
</style>