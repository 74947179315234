import axios from 'axios';

export default {
    async saveBook(params) {
        return await axios.post('/api/v1/restaurant/save-books', params);
    },
    async getBooks(params) {
        return await axios.post('/api/v1/restaurant/get-books', params);
    },
    async getPeriods(params) {
        params['is_book'] = true;
        return await axios.post('/api/v1/restaurant/get-periods', params);
    },
    async getPeriodMenu(params) {
        return await axios.post('/api/v1/restaurant/get-period-menu', params);
    },
    async getMenu(params) {
        return await axios.post('/api/v1/restaurant/get-menu', params);
    },
    async getAllMenusByUnit(params) {
        return await axios.post('/api/v1/restaurant/get-all-periods-by-unit', params);
    }
}