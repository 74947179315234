export default {
  loading(context, value) {
    context.commit('loading', value);
  },
  setConfigs(context, value) {
    context.commit('setConfigs', value);
  },
  setPusher(context, value) {
    context.commit('setPusher', value);
  },
  setBackdrop(context, value) {
    context.commit('setBackdrop', value);
  },
  openMenuNav(context, value) {
    context.commit('setBackdrop', value);
    context.commit('openMenuNav', value);
  },
  setError(context, err) {
    // console.warn('Erro desconhecido: ', err);
    context.dispatch('loading', false);

    let message = '';
    let url;

    if ('response' in err) {
      if (err.response.status == 429) {
        message = err.response.statusText;
      } else if (err.response) {
        message = err.response.data.message;
      }

      if ('data' in err.response && 'url' in err.response.data) {
        url = err.response.data.url;
      }
    } else if ('request' in err.request) {
      message = err.message;
    } else {
      message = 'Ops, aconteceu algum erro desconhecido. Tente novamente em alguns minutos.';
    }

    if (message == '') {
      context.commit('setError', { open: false, message: message, url: url, key: (new Date().getTime()) });
    } else {
      context.commit('setError', { open: true, message: message, url: url, key: (new Date().getTime()) });
    }
  },
  setAlert(context, message) {
    let payload = {};
    payload.key = new Date().getTime();
    payload.message = message;
    payload.open = true;

    context.commit('setAlert', payload);
  },
  setModalAlert(context, payload) {
    payload.key = new Date().getTime();

    context.commit('setModalAlert', payload);
  }
};