import Order from '@/models/Order';

export default {
  async getOrders(context, params) {
    try {
      context.dispatch('App/loading', true, { root: true });
      context.commit('loading', true);

      params.unit = context.rootGetters['User/unit'];

      const response = await Order.getOrders(params);

      context.commit('setOrders', response.data.data);
      context.dispatch('App/loading', false, { root: true });
      context.commit('loading', false);

      return response.data.data;
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async clearPayload(context) {
    await context.commit('clearPayload');
  },
  setPayload(context, value) {
    context.commit('setPayload', value);
  },
  async setPeriodsModal(context, params) {
    try {
      context.dispatch('App/loading', true, { root: true });

      params.unit_id = context.rootGetters['User/unit'];

      context.commit('setPayload', params);

      params.type = 'order';
      const response = await Order.getPeriods(params);

      context.dispatch('App/loading', false, { root: true });

      context.commit('setPeriodsModal', response.data.data);
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async setLocalities(context) {
    try {
      context.dispatch('App/loading', true, { root: true });

      let params = {};
      params.unit_id = context.rootGetters['User/unit'];

      const response = await Order.getLocalities(params);

      context.dispatch('App/loading', false, { root: true });

      context.commit('setLocalities', response.data.data);
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async setPeriodMenu(context, params) {
    try {
      context.dispatch('App/loading', true, { root: true });

      await context.commit('setPayload', params);

      const response = await Order.getPeriodMenu(context.getters.payload);

      context.dispatch('App/loading', false, { root: true });

      context.commit('setPeriodMenu', response.data);
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async getTotalOrderPeriod(context, params) {
    try {
      context.dispatch('App/loading', true, { root: true });

      const response = await Order.getOrderPeriod(context.getters.payload);

      context.dispatch('App/loading', false, { root: true });

      context.commit('setOrderPeriod', response.data);
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async deleteOrder(context, params) {
    try {
      context.dispatch('App/loading', true, { root: true });

      await Order.deleteOrder(params);

      context.dispatch('App/loading', false, { root: true });

      context.dispatch('getOrders', params);
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async saveOrder(context, params) {
    try {
      context.dispatch('App/loading', true, { root: true });

      const response = await Order.saveOrder(context.getters.payload);

      let data = response.data.data;

      context.dispatch('App/loading', false, { root: true });
      context.commit('clearPayload');

      context.dispatch('App/setModalAlert', {
        closeModal: 'modal-schedule-order',
        type: 'order',
        title: 'Pedido concluído',
        message: 'Dados do Pedido',
        image: 'agendamento-concluido.png',
        response: data,
        open: true,
      }, { root: true });

      return response;
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
};