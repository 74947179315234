<template>
	<aside :class="{'aside-menu': true, 'aside-menu-active': menuNavOpen}">
		<div class="aside-menu-itens">
			<div class="aside-menu-itens-header">
				<div class="content-menu">
					<a @click="openMenuNav(!menuNavOpen)" class="content-menu-item btn-menu">
						<h2 class="content-menu-item-title">Gestão de conta</h2>
						<svg xmlns="http://www.w3.org/2000/svg" width="17.951" height="16.157" viewBox="0 0 17.951 16.157">
							<path
								d="M9.8,109.708l-3.321-3.321H16.734a1.215,1.215,0,0,0,1.215-1.215v-1.215a1.215,1.215,0,0,0-1.215-1.215H6.476L9.8,99.42a1.215,1.215,0,0,0,0-1.719l-.859-.859a1.215,1.215,0,0,0-1.719,0L.355,103.7a1.215,1.215,0,0,0,0,1.719l6.863,6.863a1.215,1.215,0,0,0,1.719,0l.859-.859a1.215,1.215,0,0,0,0-1.719Z"
								transform="translate(17.95 112.643) rotate(180)" fill="#005aa1" />
						</svg>
					</a>
				</div>
			</div>
			<div class="aside-menu-itens-links">
				<a v-if="configs.has_recharge_balance" 
					 @click="$root.openModal('modal-carry-wallet')">
					<img class="img-fluid" src="@/assets/img/manage-account/carry-wallet.png" alt="carregar carteira"
						title="carregar carteira" />
					<p>Carregar Carteira</p>
				</a>
				<a v-if="configs.has_recharge_balance" @click="openTransactions">
					<img class="img-fluid" src="@/assets/img/manage-account/moviment-account.png" alt="movimentos de conta"
						title="movimentos de conta" />
					<p>Movimentos de conta</p>
				</a>
				<a data-toggle="modal" @click="$root.openModal('modal-data-person')">
					<img class="img-fluid" src="@/assets/img/manage-account/data-person.png" alt="dados pessoais"
						title="dados pessoais" />
					<p>Dados pessoais</p>
				</a>
				<a data-toggle="modal" @click="$root.openModal('modal-change-password')">
					<img class="img-fluid" src="@/assets/img/manage-account/change-password.png" alt="alterar password"
						title="alterar password" />
					<p>Alterar password</p>
				</a>
			</div>
			<a @click="logoff" class="aside-menu-itens-finish-session">
				Terminar sessão
				<img class="img-fluid" src="@/assets/img/manage-account/loggof.png" alt="terminar sessao"
					title="terminar sessao" />
			</a>
		</div>
	</aside>
</template>
<script>
	import {mapGetters, mapActions} from 'vuex';

	export default {
		computed: {
			...mapGetters('App', ['configs', 'menuNavOpen']),
		},
		methods: {
			...mapActions('App', ['openMenuNav']),
			...mapActions('User', ['getTransactions']),
			logoff(){
			
				sessionStorage.removeItem('apprest_token');
				//força a saida para pagina do cartao
				if(sessionStorage.getItem('eurest_url_cartao')){
					window.location.href = sessionStorage.getItem('eurest_url_cartao');
				}else{
					window.location.reload();
				}
			},
			openTransactions(){
				this.getTransactions();
				this.$root.openModal('modal-moviment-account');
			}
		}
	}
</script>