export default {
  loading(state, payload) {
    state.loading = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  setConfigs(state, payload) {
    state.configs = payload;
  },
  setPusher(state, payload) {
    state.pusher = payload;
  },
  setBackdrop(state, payload){
  	state.backdrop = payload;
  },
  openMenuNav(state, payload){
  	state.menuNavOpen = payload;
  },
  setAlert(state, payload){
    state.alert = payload;
  },
  setModalAlert(state, payload){    
    state.modalAlert = payload;
  }
};