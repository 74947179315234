<template>
	<div class="modal modal-status fade in" tabindex="-1" role="dialog" id="modal-status">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-body-status">
            <!-- RESERVA CONCLUIDA -->
            <div v-if="modalAlert.type == 'book'" class="modal-body-status-text">
              <img  class="img-fluid" src="@/assets/img/status/agendamento-concluido.png"/>
              <h2>{{ modalAlert.title}}</h2>
            </div>
            <!-- RESERVA CANCELADA -->
            <div v-if="modalAlert.type == 'book-cancel'" class="modal-body-status-text">
              <img  class="img-fluid" src="@/assets/img/status/cancel.png"/>
              <h2>{{ modalAlert.title}}</h2>
            </div>
            <!-- PEDIDO CONCLUIDO -->
            <div v-if="modalAlert.type == 'order'" class="modal-body-status-text">
              <img  class="img-fluid" src="@/assets/img/status/agendamento-concluido.png"/>
              <h2>{{ modalAlert.title}}</h2>
            </div>
            
            <!-- PAGAMENTO CONCLUIDO -->
            <div v-if="modalAlert.type == 'payment-success'" class="modal-body-status-text">
              <img class="img-fluid" src="@/assets/img/status/pagamento-concluido.png" title="pagamento concluido"/>
              <h2>Pagamento Concluído</h2>
            </div>
            <!-- PAGAMENTO EXPIRADO -->
            <div v-if="modalAlert.type == 'payment-error'" class="modal-body-status-text">
              <img class="img-fluid" src="@/assets/img/status/cancel.png" title="pagamento expirado"/>
              <h2>Pagamento Cancelado/Expirado</h2>
            </div>
            <!---------->
            <!-- PEDIDO CONCLUIDO -->
            <!-- <div class="modal-body-status-text">
              <img class="img-fluid" src="@/assets/img/status/pedido-concluido.png" alt="pedido concluido" title="pedido concluido"/>
              <h2> Pedido Concluído</h2>
            </div> -->
            <!---------->
            <div v-if="modalAlert.type == 'book'" class="modal-body-status-description">
              <h3>{{modalAlert.message}}</h3>
              <p>{{ modalAlert.response.date_at|moment("DD/MM") }}- {{ modalAlert.response.period.name }}</p>
              <p v-if="modalAlert.response.menu && Object.keys(modalAlert.response.menu).length > 0">{{ modalAlert.response.menu.name }}</p>
              <p v-else>{{ modalAlert.response.menu_product.name }}</p>
            </div>

            <div v-if="modalAlert.type == 'order'" class="modal-body-status-description">
              <h3>{{modalAlert.message}}</h3>
              <p>{{ modalAlert.response.date_at|moment("DD/MM") }} às {{ modalAlert.response.hour_delivery }} - {{ modalAlert.response.period.name }}</p>
              <p v-if="modalAlert.response.menu && Object.keys(modalAlert.response.menu).length > 0">{{ modalAlert.response.menu.name }}</p>
              <p v-else>{{ modalAlert.response.menu_product.name }}</p>
              <div align="left">
              <template v-for="(product, index) in modalAlert.response.order_payload">
                <p :key="index">- {{ product.produto.descricao }}</p>
              </template>
              </div>
              <p v-if="Object.keys(modalAlert.response.locality).length > 0">Local de entrega: {{ modalAlert.response.locality.name }}</p>
            </div>
          </div>
          <button @click="closeModalAndRefresh" class="modal-body-btn-close" data-dismiss="modal">
            Fechar
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  name: "ModalAlert",
  computed: {
    ...mapGetters('App', ['modalAlert']),
  },
  methods: {
    ...mapActions('App', ['setModalAlert']),
    closeModalAndRefresh() {
      this.$root.closeModal('modal-status');
      window.location.reload();
    }
  },
  mounted(){  	    
  	const self = this;
    //console.log("Modal alert", self.modalAlert)
    if(self.modalAlert.closeModal){
      self.$root.closeModal(self.modalAlert.closeModal);
    }

  	if(self.modalAlert.open){
  	 self.$root.openModal('modal-status');
  	}
  }
}
</script>