<template>
  <div
    class="modal modal-data-person fade in"
    tabindex="-1"
    role="dialog"
    id="modal-data-person"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <a
          @click="$root.closeModal('modal-data-person')"
          class="modal-header"
          data-dismiss="modal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.153"
            height="13.639"
            viewBox="0 0 15.153 13.639"
          >
            <path
              d="M8.27,107.648l-2.8-2.8h8.66a1.026,1.026,0,0,0,1.026-1.026v-1.026a1.026,1.026,0,0,0-1.026-1.026H5.467l2.8-2.8a1.026,1.026,0,0,0,0-1.451l-.726-.726a1.026,1.026,0,0,0-1.451,0L.3,102.579a1.026,1.026,0,0,0,0,1.451l5.794,5.794a1.026,1.026,0,0,0,1.451,0l.726-.726a1.026,1.026,0,0,0,0-1.451Z"
              transform="translate(0.001 -96.485)"
              fill="#005aa1"
            />
          </svg>
          <h2 class="modal-title">Dados Pessoais</h2>
        </a>
        <div class="modal-body">
          <form ref="formProfile" @submit.prevent="handleSubmit">
            <input type="hidden" name="modal_origin" :value="modalOrigin" />
            <div class="modal-body-data-person">
              <div class="content-input">
                <label> Nome completo </label>
                <input
                  type="text"
                  name="name"
                  :value="user.name"
                  placeholder=""
                  disabled
                />
              </div>
              <div class="content-input">
                <label> NIF </label>
                <input type="text" name="nif" :value="user.nif" />
              </div>
              <div class="content-input">
                <label> Email </label>
                <input
                  type="email"
                  name="email"
                  :value="user.email"
                  placeholder=""
                />
              </div>
              <div class="content-input">
                <label> Telemóvel </label>
                <!-- <input
                  type="tes"
                  name="mobile"
                  :value="user.mobile"
                  placeholder=""
                  maxlength="9"
                  required
                /> -->
                <vue-tel-input
                  class="py-3"
                  @validate="validateInput"
                  v-model="mobile"
                  mode="international"
                  :inputOptions="options"
                  defaultCountry="PT"
                  :validCharactersOnly="true"
                ></vue-tel-input>
                <input
                  required
                  type="hidden"
                  name="mobile_ddi"
                  :value="mobile_ddi"
                />
                <input
                  required
                  type="hidden"
                  name="mobile_raw"
                  :value="mobile_raw"
                />
              </div>
            </div>
            <button
              type="submit"
              class="modal-body-btn-record-changes"
              :disabled="btnDisabled"
              :style="{
                backgroundColor: btnDisabled ? '#ccc' : '',
                opacity: btnDisabled ? 0.5 : 1,
                cursor: btnDisabled ? 'not-allowed' : 'pointer',
              }"
            >
              Gravar Alterações
            </button>
          </form>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
export default {
  name: "ModalProfile",
  data() {
    return {
      mobile: "",
      mobile_ddi: "",
      mobile_raw: "",
      modalOrigin: "modal_profile",
      options: {
        placeholder: "Digite um telemóvel",
        maxlength: 25,
        showDialCode: true,
        required: true,
        autocomplete: "on",
        name: "mobile",
      },
      btnDisabled: false,
    };
  },
  components: {
    VueTelInput,
  },
  computed: {
    ...mapGetters("User", ["user"]),
  },
  watch: {
    //Garante que carrega o user para setar o mobile.
    user: function (user) {
      if (user) {
        this.mobile = `+${user?.ddi}${user?.mobile}`;
      }
    },
  },
  methods: {
    ...mapActions("User", ["updateAccount"]),
    async handleSubmit() {
      let formData = this.$root.getAllData(this.$refs.formProfile);

      if (this.$refs.formProfile.checkValidity()) {
        try {
          const response = await this.updateAccount(formData);
          this.$alert("Registo actualizado!");
        } catch (error) {
          console.error(error);
        }
      } else {
        this.$refs.formProfile.reportValidity();
      }
    },
    validateInput(obj) {
      this.btnDisabled = !obj.valid || false;
      if (obj.valid == true) {
        this.mobile_raw = obj.nationalNumber;
        this.mobile_ddi = obj.countryCallingCode;
      }
    },
  },
};
</script>
