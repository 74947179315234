import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    user: {},
    transactions: [],
    token: null,
    unit: null,
    minimum_recharge: null,
    payment: {
    	key: (new Date().getTime())
    }
  },
  actions,
  getters,
  mutations,
};