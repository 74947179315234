<template>
    <div></div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  name: "AlertError",
  computed: {
    ...mapGetters('App', ['error']),
  },
  methods: {
    ...mapActions('App', ['setError']),
  },
  async mounted(){  	    
      const self = this;

    if(self.error.url){      
      window.location.href= self.error.url;
      return;
    }

  	if(self.error.open){
          try {
              await self.$alert(self.error.message);
              self.setError('');
          } catch (error) {
              console.error(error);
          }
      }
  }
}
</script>