<template>
  <div
    class="modal modal-data-person fade in"
    tabindex="-1"
    role="dialog"
    id="modal-change-password"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <a
            @click="
              !hasToUpdatePassword()
                ? $root.closeModal('modal-change-password')
                : denyMessage()
            "
            data-dismiss="modal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.153"
              height="13.639"
              viewBox="0 0 15.153 13.639"
            >
              <path
                d="M8.27,107.648l-2.8-2.8h8.66a1.026,1.026,0,0,0,1.026-1.026v-1.026a1.026,1.026,0,0,0-1.026-1.026H5.467l2.8-2.8a1.026,1.026,0,0,0,0-1.451l-.726-.726a1.026,1.026,0,0,0-1.451,0L.3,102.579a1.026,1.026,0,0,0,0,1.451l5.794,5.794a1.026,1.026,0,0,0,1.451,0l.726-.726a1.026,1.026,0,0,0,0-1.451Z"
                transform="translate(0.001 -96.485)"
                fill="#005aa1"
              />
            </svg>
          </a>

          <h2 v-if="hasToUpdatePassword()" class="modal-title">
            Atualização da password
          </h2>
          <h2 v-else class="modal-title">Alterar Password</h2>

          <button
            v-if="hasToUpdatePassword()"
            @click="
              hasToUpdatePassword()
                ? doLogout()
                : $root.closeModal('modal-change-password')
            "
            data-dismiss="modal"
            type="button"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="text-center pb-5" v-if="hasToUpdatePassword()">
          <span class="text-danger"
            >É necessário atualizar a sua password a cada 3 meses.</span
          >
        </div>
        <div class="modal-body">
          <form ref="formPassword" @submit.prevent="handleSubmit">
            <input type="hidden" name="modal_origin" :value="modalOrigin" />
            <div class="modal-body-data-person">
              <div class="content-input">
                <label> Nova Password </label>
                <input
                  v-model="password"
                  type="password"
                  name="password"
                  value=""
                  placeholder=""
                  required
                />

                <small
                  v-if="validPassword == false"
                  id="passwordHelp"
                  class="form-text text-danger"
                  >A password deve conter:
                  <span :class="has_minimum_lenth ? 'has_required' : ''"
                    >Mínimo 8 caracteres</span
                  >,
                  <span :class="has_uppercase ? 'has_required' : ''"
                    >pelo menos uma maiúscula</span
                  >,
                  <span :class="has_number ? 'has_required' : ''"
                    >pelo menos 1 número</span
                  >, e
                  <span :class="has_special ? 'has_required' : ''"
                    >pelo menos 1 caracter especial</span
                  ></small
                >
                <small v-else class="form-text text-success">
                  Password válida!
                </small>
              </div>
              <div class="content-input">
                <label> Confirmação Password </label>
                <input
                  type="password"
                  name="password_confirmation"
                  value=""
                  placeholder=""
                  required
                />
              </div>
            </div>
            <button
              type="submit"
              class="modal-body-btn-record-changes"
              :disabled="!validPassword"
              :style="{
                backgroundColor: !validPassword ? '#ccc' : '',
                opacity: !validPassword ? 0.5 : 1,
                cursor: !validPassword ? 'not-allowed' : 'pointer',
              }"
            >
              Gravar Alterações
            </button>
          </form>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      modalOrigin: "modal_password",
      password: "",
      has_minimum_lenth: false,
      has_number: false,
      has_uppercase: false,
      has_special: false,
      validPassword: false,
    };
  },
  watch: {
    password() {
      this.has_minimum_lenth = this.password.length > 8;
      this.has_number = /\d/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_special = /[!@#$%^&*)(+=._-]/.test(this.password);
      if (
        this.has_minimum_lenth &&
        this.has_number &&
        this.has_uppercase &&
        this.has_special
      ) {
        this.validPassword = true;
      } else {
        this.validPassword = false;
      }
    },
  },
  name: "ModalProfile",
  computed: {
    ...mapGetters("User", ["user"]),
  },
  methods: {
    ...mapActions("User", ["updateAccount", "logout"]),
    async doLogout() {
      const user = this.user;
      const response = await this.logout(user);
      if (response.data.logout == true) {
        this.$root.closeModal("modal-change-password");
        this.$router.push({
          name: "login",
          params: { unit: response.data.unitName },
        });
      }
    },
    async handleSubmit() {
      let formData = this.$root.getAllData(this.$refs.formPassword);

      if (this.$refs.formPassword.checkValidity()) {
        const response = await this.updateAccount(formData);
        await this.$alert("Registo actualizado!");
        if (this.hasToUpdatePassword() == false) {
          this.$root.closeModal("modal-change-password");
          //clear inputs
          this.$refs.formPassword.reset();
          this.validPassword = false;
        }
      } else {
        this.$refs.formPassword.reportValidity();
      }
    },
    hasToUpdatePassword() {
      //verify if today's date is higher pw_updated_at  in 90 days
      if (this.user.pw_updated_at) {
        let today = new Date();
        let pw_updated_at = new Date(this.user.pw_updated_at);
        let diff = today - pw_updated_at;
        let days = Math.floor(diff / (1000 * 60 * 60 * 24));
        //se tiver mais que 3 meses sem alterar a password abre o modal
        if (days >= 90) {
          return true;
        }
        return false;
      }
      return false;
    },
    async denyMessage() {
      await this.$alert("A atualização é obrigatória.");
    },
  },

  mounted() {},
};
</script>
<style scoped>
.has_required {
  text-decoration: line-through;
  color: #689868;
}
</style>