<template>
    <div></div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  name: "Alert",
  computed: {
    ...mapGetters('App', ['alert']),
  },
  methods: {
    ...mapActions('App', ['setAlert']),
  },
  async mounted(){  	    
      if(this.alert.open){
          try {
              await this.$alert(this.alert.message);
            // this.setAlert('');
            window.location.reload();
          } catch (error) {
              console.error(error);
          }
      }
  }
}
</script>