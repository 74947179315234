<template>
  <div class="modal modal-carry-wallet fade in" tabindex="-1" role="dialog" id="modal-carry-wallet">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <a @click="$root.closeModal('modal-carry-wallet')" class="modal-header" data-dismiss="modal">
          <svg xmlns="http://www.w3.org/2000/svg" width="15.153" height="13.639" viewBox="0 0 15.153 13.639">
            <path
              d="M8.27,107.648l-2.8-2.8h8.66a1.026,1.026,0,0,0,1.026-1.026v-1.026a1.026,1.026,0,0,0-1.026-1.026H5.467l2.8-2.8a1.026,1.026,0,0,0,0-1.451l-.726-.726a1.026,1.026,0,0,0-1.451,0L.3,102.579a1.026,1.026,0,0,0,0,1.451l5.794,5.794a1.026,1.026,0,0,0,1.451,0l.726-.726a1.026,1.026,0,0,0,0-1.451Z"
              transform="translate(0.001 -96.485)" fill="#005aa1" />
          </svg>
          <h2 class="modal-title">Carregar carteira</h2>
        </a>
        <div class="modal-body">
          <form ref="formPayment" @submit.prevent="handleSubmit">
            <div class="modal-body-carry-wallet">
              <div class="modal-body-carry-wallet-header">
                <h2>Selecione o valor</h2>
              </div>
              <div class="modal-body-carry-wallet-element">
                <div class="container">
                  <div class="row">
                    <div class="wallet-values col p-1" v-for="n in rechargeOptions" :key="n" @click="addBalance(n)">
                      <a>{{ n | money }}</a>
                    </div>
                  </div>
                </div>

                <div class="wallet-personalize-value">
                  <input type="number" name="price" v-model="balance" required />
                </div>
                <!-- <small
                  v-if="isBalanceValid == false"
                  id="balanceHelp"
                  class="pl-1 form-text text-danger h6"
                >
                  <span :class="isBalanceValid ? 'has_required' : ''"
                    >Não permite valor inferior ao definido para carregamento
                    ({{ minimum_recharge }}€)</span
                  >
                </small> -->
              </div>
            </div>
            <div class="modal-body-payment">
              <div class="modal-body-payment-header">
                <h2>Pagamento MBWAY</h2>
              </div>
              <div class="modal-body-payment-element">
                <div class="content-input">
                  <img class="img-fluid" src="../assets/img/schedules/mb-way.png" alt="mb-way" title="mb-way" />
                  <input type="tel" name="mobile" v-model="phone" placeholder="Nº telemóvel" maxlength="9" required />
                </div>
              </div>
            </div>
            <button type="submit" :class="`modal-body-btn-payment` +
              (isBalanceValid == false ? ' btn btn-secondary' : '')
              " :disabled="isBalanceValid == false">
              Pagamento
            </button>
          </form>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import User from "@/models/User";
export default {
  data() {
    return {
      balance: "",
      phone: "",
      isBalanceValid: false,
      rechargeOptions: [],
      routeName: "",
    };
  },
  computed: {
    ...mapGetters("User", ["user", "unit", "minimum_recharge"]),
  },

  methods: {
    ...mapActions("User", ["addBalanceUser"]),
    addBalance(balance) {
      this.balance = balance;
    },
    async handleSubmit() {
      let formData = this.$root.getAllData(this.$refs.formPayment);

      formData.unit_id = this.unit;
      if (this.$refs.formPayment.checkValidity()) {
        await this.addBalanceUser(formData);
      } else {
        this.$refs.formPayment.reportValidity();
      }
    },

    //do a method to get the minimum recharge list of options

    getRechargeOptions() {
      if (this.minimum_recharge && this.minimum_recharge < 10) {
        this.rechargeOptions = [];

        let min_recharge = this.minimum_recharge;
        this.rechargeOptions.push(this.minimum_recharge);

        for (let i = 0; i < 5; i++) {
          let recharge = Math.round((min_recharge += this.minimum_recharge));
          this.rechargeOptions.push(recharge);
        }
      }
      if (this.minimum_recharge && this.minimum_recharge >= 10) {
        this.rechargeOptions = [];

        let min_recharge = this.minimum_recharge;
        this.rechargeOptions.push(this.minimum_recharge);

        for (let i = 0; i < 5; i++) {
          let recharge = Math.round(
            (min_recharge += this.minimum_recharge / 2)
          ); //division by 2 to get not high values
          this.rechargeOptions.push(recharge);
        }
      }
    },
  },
  watch: {
    user(promise) {
      this.phone = promise.mobile;
    },
    balance() {
      //balance maior que 200 euros não deixa
      if ((this.balance < this.minimum_recharge) || (this.balance > 200) || (this.balance < 0)) {
        this.isBalanceValid = false;
      } else {
        this.isBalanceValid = true;
      }
    },
    minimum_recharge() {
      this.getRechargeOptions();
    },
  },

  async created() {
    //let eurest_unit = localStorage.getItem("eurest_unit");
    //devemos pegar a unidade do vuex
    let eurest_unit = this.unit;
    //get router name
    if (!window.location.pathname.includes("login")) {
      try {
        const response = await User.getMinimumRecharge(eurest_unit);
        if (response.data.minimum_recharge) {
          this.$store.dispatch("User/setMinimumRecharge", {
            minimum_recharge: response.data.minimum_recharge,
          });
          this.getRechargeOptions();
        }
      } catch (error) {
        console.error();
      }
    }
  },
};
</script>
<style scoped>
.has_required {
  text-decoration: line-through;
  color: #689868;
}

.btn-inactive {
  background-color: #e0e0e0;
  color: #fff;
}
</style>