import User from '@/models/User';

export default {
  async setInfo(context, value) {
    
    try {
      context.dispatch('App/loading', true, { root: true });

      const response = await User.getInfo();
      let user = response.data.data;

      context.commit('setInfo', user);
      context.commit('setUnit', user.unit_id);
      context.dispatch('checkPaymentOpened');
      context.dispatch('App/setConfigs', response.data.data.unit, { root: true });
      context.dispatch('App/loading', false, { root: true });

      return response.data.data;
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async login(context, payload) {
    try {
      context.dispatch('App/loading', true, { root: true });

      const response = await User.login(payload);
      context.dispatch('App/loading', false, { root: true });

      let user = response.data.data;

      context.commit('setToken', user.token);
      context.commit('setInfo', user);
      context.commit('setUnit', user.unit_id);

      const rechargeResponse = await User.getMinimumRecharge(user.unit_id);
      if (rechargeResponse.data.minimum_recharge) {
        context.commit('setMinimumRecharge', rechargeResponse.data.minimum_recharge);
      }

      context.dispatch('App/setConfigs', user.unit, { root: true });

      return user;
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async logout(context, payload) {
    try {
      const response = await User.logout(payload);
      context.commit('setToken', null);
      context.commit('setInfo', null);
      context.commit('setUnit', null);

      return response;
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async updateAccount(context, payload) {
    try {
      context.dispatch('App/loading', true, { root: true });

      const response = await User.updateAccount(payload);
      context.dispatch('App/loading', false, { root: true });

      let user = response.data.data;
      context.commit('setInfo', user);

      return user;
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  setPayment(context, payload) {
    context.commit('setPayment', payload);
  },
  async checkPaymentOpened(context) {
    try {
      context.dispatch('App/loading', true, { root: true });

      const response = await User.checkPaymentOpened();
      context.dispatch('App/loading', false, { root: true });

      let data = response.data;
      if (Object.keys(data).length && data.status_payment_id != 1) {
        await context.dispatch('setInfo');
      }
      context.commit('setPayment', data);

      return data;
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async addBalanceUser(context, payload) {
    try {
      context.dispatch('App/loading', true, { root: true });

      const response = await User.addBalanceAccount(payload);
      context.dispatch('App/loading', false, { root: true });

      let data = response.data;
      context.commit('setPayment', data);

      return data;
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async callOrderPayment(context, payload) {
    try {
      context.dispatch('App/loading', true, { root: true });

      const response = await User.doOrderPayment(payload);
      await context.dispatch('checkPaymentOpened');
      context.dispatch('App/loading', false, { root: true });

      let data = response.data;
      context.commit('setPayment', data);

      return data;
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async callOrderPaymentWithBalance(context, payload) {
    try {
      context.dispatch('App/loading', true, { root: true });

      await User.doOrderPaymentWithBalance(payload);
      context.dispatch('App/loading', false, { root: true });
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async getTransactions(context) {
    try {
      context.dispatch('App/loading', true, { root: true });

      const response = await User.getTransactions();
      context.dispatch('App/loading', false, { root: true });

      let data = response.data;
      context.commit('setTransactions', data);

      return data;
    } catch (error) {
      context.dispatch('App/setError', error, { root: true });
    }
  },
  async setMinimumRecharge(context, payload) {
    context.commit('setMinimumRecharge', payload.minimum_recharge);
  }
};