export default {
  setOrders(state, payload) {
    state.orders = payload;
  },
  setLocalities(state, payload) {
    state.localities = payload;
  },
  loading(state, payload) {
    state.loading = payload;
  },
  setPeriodsModal(state, payload) {
    state.periods = payload;
  },
  setOrderPeriod(state, payload) {
    state.orderPeriod = payload;
  },
  setPeriodMenu(state, payload) {
    state.period_menu = payload;
  },
  setPayload(state, value) {
    state.payload = Object.assign({}, state.payload, value)
  },
  clearPayload(state) {
    state.payload = {};
    state.period_menu = {};
    state.periods = {};
  }
};