import Vue from 'vue';
import Vuex from 'vuex';
/**/
import App from './modules/App';
import User from './modules/User';
import Book from './modules/Books';
import Order from './modules/Orders';
import Unit from './modules/Unit';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    App,
    User,
    Book,
    Order,
    Unit,
  }
})